export default {
  methods: {
    downloadDocument({ blobData, documentName }) {
      let url = window.URL.createObjectURL(new Blob([blobData]))
      let link = document.createElement('a')
      link.href = url
      link.setAttribute('download', documentName)
      document.body.appendChild(link)
      link.click()
    },
  },
}
