export default {
  methods: {
    getParams({
      page,
      perPage,
      sortField,
      sortOrder,
      dateFrom,
      dateTo,
      type,
      additionalFilters,
    }) {
      let params = [
        `page=${page}`,
        `per_page=${perPage}`,
        `sort_field=${sortField}`,
        `sort_order=${sortOrder}`,
      ]

      if (dateFrom && dateTo) {
        if (type === 'payment') {
          params.push(`filtering_conditions[payment_date][end_date]=${dateTo}`)
          params.push(
            `filtering_conditions[payment_date][start_date]=${dateFrom}`
          )
        } else {
          params.push(
            `filtering_conditions[refund_request_date][end_date]=${dateTo}`
          )
          params.push(
            `filtering_conditions[refund_request_date][start_date]=${dateFrom}`
          )
        }
      }

      if (additionalFilters && Array.isArray(additionalFilters)) {
        additionalFilters.forEach((filter) => {
          params.push(...filter)
        })
      }

      return params
    },
  },
}
