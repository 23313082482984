<template>
  <r-page page-title="Admin Payment">
    <template #page-header>
      <h2 class="page-title">Admin Payment</h2>
      <r-menu-tab :level="2" />
    </template>
    <template #action-bar>
      <div class="level action-bar-padding">
        <div class="level-left">
          <b-button
            :disabled="checkedRows.length === 0"
            type="is-primary"
            label="Reject"
            icon-left="minus-circle"
            @click.native="openRejectModal"
          />
          <b-button
            :disabled="checkedRows.length === 0"
            type="is-primary"
            label="Approve"
            icon-left="check"
            @click.native="openApproveModal"
          />
          <b-button
            type="is-primary"
            outlined
            icon-left="file-document"
            label="Export Excel"
            @click.native="exportExcel()"
            :loading="isExporting"
          />
        </div>
        <div class="level-right">
          <b-datepicker
            v-model="dates"
            icon="calendar-range"
            placeholder="Select Date Range"
            :icon-right="dates.length === 0 ? '' : 'close'"
            icon-right-clickable
            @icon-right-click="clearDate"
            @input="filterByDate()"
            class="filter-datepicker"
            range
          ></b-datepicker>
          <div v-if="statusArray.length !== 0" class="filter-active status">
            Status +{{ statusArray.length }}
            <span>
              <b-icon
                class="pointer-click"
                icon="close"
                custom-size="mdi-18px"
                @click.native="clearStatus()"
              />
            </span>
          </div>
          <b-button
            type="is-light"
            icon-left="filter-outline"
            class="mx-2"
            @click.native="openFilter"
          >
            <img
              src="@/assets/images/filter-dot.svg"
              v-if="statusArray.length !== 0"
              class="filter-dot-button"
            />
            Filter
          </b-button>
          <RefundFilter
            :isFilterOpen="isFilterOpen"
            :statuses="statuses"
            @closeFilter="closeFilter"
            @filterStatus="filterByStatus($event)"
            @filterByDate="filterByDate($event)"
            @resetState="resetState"
            @loadData="loadRefundIndex"
          />
          <b-input
            icon-right="magnify"
            v-model="search"
            placeholder="Search"
            @keydown.native.enter="searchIconClick(search)"
            icon-right-clickable
            @icon-right-click="searchIconClick(search)"
            :loading="isLoading"
          />
        </div>
      </div>
    </template>
    <template #page-content>
      <b-table
        :data="data ? data.data : []"
        :loading="isLoading"
        checkable
        :custom-is-checked="
          (a, b) => {
            return a.id === b.id
          }
        "
        :is-row-checkable="(row) => row.status === 'waiting'"
        :checked-rows.sync="checkedRows"
        paginated
        backend-pagination
        backend-sorting
        :total="data && data.meta ? data.meta.total : 0"
        :per-page="perPage"
        :default-sort-direction="sortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :current-page.sync="page"
        class="table-admin-payment-management"
      >
        <b-table-column field="id" label="Order ID" v-slot="props" sortable>
          <span>{{ props.row.id }}</span>
        </b-table-column>
        <b-table-column
          field="package"
          label="Packages"
          width="20%"
          v-slot="props"
        >
          <span>{{ props.row.package }}</span>
        </b-table-column>
        <b-table-column
          field="buyer_email"
          width="15%"
          v-slot="props"
          label="Buyer"
        >
          <span>{{ props.row.buyerEmail }}</span>
        </b-table-column>
        <b-table-column
          field="refund_request_date"
          label="Date Refund"
          v-slot="props"
          width="15%"
        >
          <span>
            {{ formatDate(props.row.refundRequestDate, 'DD MMMM YYYY') }}
          </span>
        </b-table-column>
        <b-table-column
          field="refund_date"
          label="Actual Refund Date"
          width="11%"
          sortable
          v-slot="props"
        >
          <span>
            {{
              props.row.refundDate
                ? formatDate(props.row.refundDate, 'DD MMMM YYYY')
                : '-'
            }}
          </span>
        </b-table-column>
        <b-table-column
          field="total_amount"
          label="Amount"
          v-slot="props"
          sortable
          width="10%"
        >
          <span>
            {{
              props.row.totalAmount
                ? 'Rp ' + formatCurrency(props.row.totalAmount)
                : '-'
            }}
          </span>
        </b-table-column>
        <b-table-column
          field="status"
          label="Status"
          width="10%"
          v-slot="props"
        >
          <div class="refund-status is-capitalized" :class="props.row.status">
            {{ props.row.status }}
          </div>
        </b-table-column>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                No data yet, please contact us if you have any problems
                (hello@getrise.id)
              </p>
            </div>
          </section>
        </template>
      </b-table>
      <warning-modal
        :isModalOpen="isRejected"
        :title="'Are you sure ?'"
        :subtitle="'Are you sure you want to reject this refund(s) ?'"
        @close="closeWarningModal"
        @run="massUpdateRefund('rejected')"
      />
      <warning-modal
        :isModalOpen="isApproved"
        :title="'Are you sure ?'"
        :subtitle="'Are you sure you want to approve this refund(s) ?'"
        @close="closeWarningModal"
        @run="massUpdateRefund('approved')"
      />
    </template>
  </r-page>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import WarningModal from '../../components/WarningModal.vue'
import RefundFilter from '../../components/RefundFilter.vue'
import moment from 'moment'
import downloadDocument from '@/views/AdminPayment/mixins/downloadDocument'
import indexingParams from '@/views/AdminPayment/mixins/indexingParams'

export default {
  components: { RefundFilter, WarningModal },
  mixins: [downloadDocument, indexingParams],

  data() {
    return {
      isLoading: false,
      isExporting: false,
      search: '',
      data: null,
      perPage: 10,
      page: 1,
      lastPage: 1,
      total: 0,
      sortField: 'id',
      sortOrder: 'desc',
      isFilterOpen: false,
      statusArray: [],
      checkedRows: [],
      dates: [],
      dateFrom: null,
      dateTo: null,
      isApproved: false,
      isRejected: false,
      subtitle: '',
      tooltipLabel: `When filter is activated, the text will change to bold
                      and the color will change to red.`,
      statuses: [
        {
          title: 'Waiting',
          value: 'waiting',
          isClicked: false,
        },
        {
          title: 'Pending',
          value: 'pending',
          isClicked: false,
        },
        {
          title: 'Rejected',
          value: 'rejected',
          isClicked: false,
        },
        {
          title: 'Approved',
          value: 'approved',
          isClicked: false,
        },
      ],
    }
  },

  async mounted() {
    this.setRefundIndex([])
    await this.loadRefundIndex()
  },

  computed: {
    ...mapGetters({
      getRefund: 'adminPayment/getRefundIndex',
    }),
  },

  methods: {
    ...mapActions({
      fetchRefundIndex: 'adminPayment/fetchRefundIndex',
      exportReport: 'adminPayment/exportRefundReport',
      actionMassUpdateRefund: 'adminPayment/massUpdateRefund',
    }),

    ...mapMutations({
      setRefundIndex: 'adminPayment/setRefundIndex',
    }),

    warningSubtitle() {
      if (this.isRejected) {
        this.subtitle = 'are you sure you want to reject this refund?'
      }
    },

    formatCurrency(val) {
      return new Intl.NumberFormat('id-ID', {
        minimumFractionDigits: 0,
      }).format(val)
    },

    async clearStatus() {
      this.statusArray = []
      this.statuses.forEach((el) => (el.isClicked = false))
      this.setRefundIndex([])
      await this.loadRefundIndex()
    },

    formatDate(date, format) {
      return moment(date).format(format)
    },

    async exportExcel() {
      this.isExporting = true
      let params = [
        ...this.getParams({
          page: this.page,
          perPage: this.perPage,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          type: 'refund',
          additionalFilters: [this.statusArray],
        }),
      ]

      await this.exportReport(params.join('&'))
        .then((response) => {
          this.downloadDocument({
            blobData: response.data,
            documentName: `refund-report.xlsx`,
          })

          this.$swal({
            icon: 'success',
            title: 'Download Success',
            text: 'Refund Report is successfully downloaded',
            timer: 4000,
            showConfirmButton: false,
          })
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Download Failed !',
            text: `Failed to download refund report file !`,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'button is-danger',
            },
          })
        })
        .finally(() => {
          this.isExporting = false
        })
    },

    onPageChange(page) {
      this.page = page
      this.loadRefundIndex()
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    resetState() {
      this.page = 1
      this.lastPage = 1
      this.dateFrom = null
      this.dateTo = null
      this.statusArray = []
      this.statuses.forEach((el) => (el.isClicked = false))
      this.setRefundIndex([])
      this.loadRefundIndex()
    },

    async searchIconClick() {
      await this.resetState()
    },

    openFilter() {
      this.isFilterOpen = true
    },

    closeFilter() {
      this.isFilterOpen = false
    },

    async loadRefundIndex() {
      this.isLoading = true
      let params = [
        ...this.getParams({
          page: this.page,
          perPage: this.perPage,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          type: 'refund',
          additionalFilters: [this.statusArray],
        }),
      ]
      params.push(`search=${this.search}`)

      try {
        const response = await this.fetchRefundIndex(params.join('&'))

        if (response && response.status === 200) {
          this.data = response.data
        }
        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (e) {
        console.log(e)
      }
      this.isLoading = false
    },

    filterByStatus(event) {
      let index = event.s
      const value = this.statusArray.indexOf(
        `filtering_conditions[status][]=${event.status.value}`
      )
      if (value > -1) {
        this.statusArray.splice(value, 1)
        this.statuses[index].isClicked = false
      } else {
        this.statusArray.push(
          `filtering_conditions[status][]=${event.status.value}`
        )
        this.statuses[index].isClicked = true
      }
    },

    clearDate() {
      this.dates = []
      this.resetState()
    },

    async filterByDate() {
      this.dateFrom =
        this.dates.length !== 0
          ? moment(this.dates[0]).format('YYYY-MM-DD')
          : null
      this.dateTo =
        this.dates.length !== 0
          ? moment(this.dates[1]).format('YYYY-MM-DD')
          : null

      this.setRefundIndex([])
      await this.loadRefundIndex()
    },

    openRejectModal() {
      this.isRejected = true
    },
    openApproveModal() {
      this.isApproved = true
    },
    closeWarningModal() {
      this.isRejected = false
      this.isApproved = false
    },
    async massUpdateRefund(status) {
      let refundIds = []
      this.failed = []
      this.closeWarningModal()

      this.checkedRows.forEach((el) => {
        refundIds.push(el.id)
      })

      await this.actionMassUpdateRefund({
        ids: refundIds,
        status: status,
      })
        .then((response) => {
          this.checkedRows = []
          let failedMessage = ''
          response.refunds
            .filter((refund) => refund.status && refund.status === 'failed')
            .forEach((refund) => {
              if (refund.message.toLowerCase().includes('midtrans')) {
                let error = refund.message.substring(
                  refund.message.indexOf('{')
                )
                let errResponse = JSON.parse(error)
                failedMessage += `<li>Failed to update refund id ${refund.id}. Midtrans API is returning error. HTTP Status Code ${errResponse.status_code} : ${errResponse.status_message}</li>`
              } else {
                failedMessage += `<li>Failed to update refund id ${refund.id}. ${refund.message}</li>`
              }
            })
          if (failedMessage) {
            this.$swal({
              icon: 'error',
              title: `Mass Update Refunds Failed !`,
              html: failedMessage,
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'button is-danger',
              },
              width: 500,
            })
          } else {
            this.$swal({
              icon: 'success',
              title: `Refunds ${status} !`,
              text: `Refunds are successfully ${status}. `,
              timer: 4000,
              showConfirmButton: false,
            })
          }
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Error !',
            text: `Failed to mass update refunds. Please try again.`,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'button is-danger',
            },
          })
        })
    },
  },
}
</script>
